"use client";

import { Locale } from "@/i18n";
import React, { useState, useEffect } from "react";

function AnnouncementBar({ locale }: { locale: Locale }) {
    return  <></>;

    const [showFlag, setShowFlag] = useState(false);
    const AnnouncementCookie = 'announcementClosedCookie2';

    useEffect(() => {
        const isClosed = localStorage.getItem(AnnouncementCookie);
        if (!isClosed) {
            setShowFlag(true);
        }
    }, []);

    const handleClose = () => {
        localStorage.setItem(AnnouncementCookie, "true");
        setShowFlag(false);
    };

    return (
        <>
            {showFlag && (
                <div
                    className="alert bg-dark border-0 rounded-0 alert-dismissible fade show text-center overflow-hidden py-2 m-0 d-flex align-items-center justify-content-between"
                    role="alert"
                >
                    <p className="text-white small m-2 d-flex align-items-center p-0">
                        <i className="fa-solid fa-code me-2"/>
                        The website and application are currently in development and are expected to be ready by the end of the year 2024.
                    </p>
                    {/* Close button */}
                    <button
                        type="button"
                        className="btn-close btn-close-white opacity-5 p-3"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={handleClose}
                    />
                </div>
            )}
        </>
    );
}

{/*

                <div className="alert py-2 m-0 bg-warning-subtle border-0 rounded-0 alert-dismissible fade show text-center overflow-hidden" role="alert">
                    <p className="text-white small m-0">
                        <i className="bi bi-braces-asterisk me-2"/>
                        Website still in development.{" "}
                        <a href="#" className="btn btn-xs btn-white ms-2 mb-0"> Explore!</a>
</p>
<button
    type="button"
    className="btn-close btn-close-white opacity-5 p-3"
    data-bs-dismiss="alert"
    aria-label="Close"
    onClick={handleClose}
/>
</div>
*/
}
{/*<section className="p-0 m-0">
                    <div className="alert alert-warning alert-dismissible" role="alert">
                        <div>Website still in development</div>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleClose}></button>
                    </div>
                </section>*/}

export default AnnouncementBar;
