import ReactGA from "react-ga4";

const initializeGA = () => {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GA_ID ?? "");
};

const trackGAEvent = (category: string, action: string, label: string) => {
  if (process.env.NODE_ENV === "production") {
    console.log("GA event:", category, ":", action, ":", label);

    ReactGA.event(action, {
      event_category: category,
      event_label: label,
      value: label,
      non_interaction: true,
    });
  }
};

export default initializeGA;
export { initializeGA, trackGAEvent };
