class UTMCampaignBuilderUtils {
  static generate(
    url: string,
    utmSource: string,
    utmMedium: string = "website",
    utmCampaign: string | null = null,
    utmContent: string | null = null,
  ): string {
    let finalURL: string = url;

    // Check if the URL already has query parameters
    const hasQueryParams: boolean = url.includes("?");

    // Create an array to hold UTM parameters
    const utmParameters: string[] = [];

    // Add UTM parameters to the array if they are provided
    utmParameters.push(`utm_source=${encodeURIComponent(utmSource)}`);
    utmParameters.push(`utm_medium=${encodeURIComponent(utmMedium)}`);
    if (utmCampaign !== null) {
      utmParameters.push(`utm_campaign=${encodeURIComponent(utmCampaign)}`);
    }
    if (utmContent !== null) {
      utmParameters.push(`utm_content=${encodeURIComponent(utmContent)}`);
    }

    // Join the UTM parameters with '&' and prefix with either '?' or '&' based on whether the URL already has parameters
    const utmString: string =
      (hasQueryParams ? "&" : "?") + utmParameters.join("&");

    // Append the UTM parameters string to the final URL
    finalURL += utmString;

    return finalURL;
  }

  static blog(url:string, articleSlug:string):string {
    return this.generate(
      url,
      'medentic.app',
      'website',
      articleSlug
    );
  }
}

export default UTMCampaignBuilderUtils;
