"use client";

import { useEffect } from "react";
import { initializeGA } from "@/lib/google-analytics";

// Define the GoogleAnalytics functional component
export default function GoogleAnalytics() {
  // Check if the Google Analytics ID is available in environment variables
  useEffect(() => {
    // Ensure that Google Analytics initializes only once per application lifetime
    if (process.env.NEXT_PUBLIC_GA_ID && !window.GA_INITIALIZED) {
      initializeGA();
      window.GA_INITIALIZED = true;
    }
  }, []);

  // The component doesn't render any UI elements
  return null;
}
