export const S3_URL = "https://assets.medentic.app";

const ImageAssets = {
  FavIconIco: `${S3_URL}/favicon/favicon.ico`,
  FavIconSVG: `${S3_URL}/favicon/favicon.svg`,
  FavIconApple: `${S3_URL}/favicon/apple-touch-icon.png`,

  SocialLogo: `${S3_URL}/logos/logotype-black.svg`,

  LightLogo: `${S3_URL}/logos/logotype-light.svg`,
  DarkLogo: `${S3_URL}/logos/logotype-black.svg`,
  DarkSymbol: `${S3_URL}/logos/symbol-black.svg`,

  BGFlag: `${S3_URL}/assets/images/flags/bg.svg`,
  UKFlag: `${S3_URL}/assets/images/flags/uk.svg`,

  Map: `${S3_URL}/images/home/map.jpg`,
};

export default ImageAssets;
